import axios from "axios";
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import {Materials} from "@/models/Materials";

//https://esi.evetech.net/v1/markets/${regionId}/history/?type_id=${typeId}
const api = axios.create({
  baseURL: 'https://api.evemarketer.com/ec/marketstat',
  headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
});


/*export async function getPrice(typeId = 0, regionId = 30000142) {
  if (typeId === 0) return [null, 0];
  try {
    const { data }  = await api.get(`json?typeid=${typeId}&usesystem=${regionId}`);
    return [null, data[0].sell.min ];
  } catch (error) {
    return [error];
  }
}*/

export async function getMaterials(regionId = 30000144) {
  try {
    let typeIDs = [...Materials.keys()].join("&typeid=");
    const { data }  = await api.get(`json?typeid=${typeIDs}&usesystem=${regionId}`);
    data.forEach((el) => {
      let type = el.sell.forQuery.types[0];
      let price = el.sell.min !== 0 ? el.sell.min : el.buy.max;
      let mat = Materials.get(type);
      mat.price = price;
      Materials.set(type, mat);
    })
    return [null, Materials];
  } catch (error) {
    return [error];
  }
}
