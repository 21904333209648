<template>
  <b-col>
    <b-card v-if="id" no-body class="overflow-hidden bg-light mb-3" style="max-width: 540px;">
      <b-row no-gutters>
        <b-col md="3">
          <b-card-img :src="'https://images.evetech.net/characters/' + id + '/portrait?size=128'" alt="Image" class="rounded-0"></b-card-img>
          <b-button class="removeChar" variant="danger" v-on:click="removeChar" v-b-tooltip.hover title="remove character">x</b-button>
          <!--b-button variant="success" v-on:click="getPlanets">get Planets</b-button-->
        </b-col>
        <b-col md="9">
          <b-card-body :title="name">
            <b-card-text>
              Current earnings:
              <b-progress :max="100">
                <b-progress-bar
                    :variant="barColorIPH()"
                    :value="currentIPH / maxIPH * 100"
                    :label="new Intl.NumberFormat().format(currentIPH)+ ' / '+new Intl.NumberFormat().format(maxIPH)+ ' ISK/h'"
                ></b-progress-bar>
              </b-progress>
              Monthly earnings:
              <b-progress :max="100">
                <b-progress-bar
                    :variant="barColorIPH()"
                    :value="currentIPH / maxIPH * 100"
                    :label="new Intl.NumberFormat().format(currentIPH * 24 * 30)+ ' / '+new Intl.NumberFormat().format(maxIPH * 24 * 30)+ ' ISK/h'"
                ></b-progress-bar>
              </b-progress>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>

    <EvePlanet v-for="planet in planets" :key="planet.planet_id" :planet="planet" :id="id" :token="token" :date="date" @created="onPlanetCreated"></EvePlanet>
  </b-col>
</template>

<script>
import { Api } from 'eve-esi-swaggerts'
import EvePlanet from './EvePlanet.vue'

export default {
  name: 'EveCharacter',
  components: {
    EvePlanet
  },
  props:  {
    refresh: String,
    token: String,
    name: String,
    id: Number,
    date: Number,
  },
  created() {
    this.getPlanets();
  },
  data: () => {
    return {
      planets: [],
      currentIPH: 0,
      maxIPH: 0,
    }
  },
  methods: {
    barColorIPH: function () {
      let percentageIPH = this.currentIPH / this.maxIPH * 100;
      return percentageIPH >= 75 ? 'success' : (percentageIPH >= 50 ? 'warning' : 'danger');
    },
    onPlanetCreated: function (currentIPH, maxIPH, extracted) {
      this.currentIPH += currentIPH;
      this.maxIPH += maxIPH;
      this.$emit('created', currentIPH, maxIPH, extracted);
    },
    getPlanets: function () {
      let eveAPI = new Api();
      eveAPI.characters.getCharactersCharacterIdPlanets(this.id, {token: this.token}).then((result) => {
        this.planets = result.data;
        this.currentIPH = 0;
        this.maxIPH = 0;
      })
    },
    removeChar: function () {
      this.$emit('removeChar', this.refresh);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .removeChar {
    position: absolute;
    top: 0;
    right: 0;
  }
  .progress-bar {
    text-shadow: 0px 0px 2px black;
    overflow: visible !important;
    padding-left: 5px;
  }
</style>
