<template>
  <div id="app">
    <b-container fluid>
      <div class="header clearfix">
        <h3 class="text-muted">Awesome Eve PI Helper
          {{ new Intl.NumberFormat().format(Math.round(currentIPH * 24 * 30)) }} / {{ new Intl.NumberFormat().format(Math.round(maxIPH * 24 * 30)) }} ISK/month
          <b-button variant="success" v-on:click="addCharacter">Add Character</b-button>
        </h3>
      </div>
      <b-container fluid>
        <b-row class="mb-2">
          <b-col>
            Produced since reset:
            <b-list-group horizontal>
              <b-list-group-item v-for="ext in extracted" :key="ext[0]">
                <img v-if="ext[0]" :src="'https://images.evetech.net/types/' + ext[0] + '/icon?size=32'"/> {{ new Intl.NumberFormat().format(ext[1]) }}
                <br/>@ <span v-if="materials.has(ext[0])">{{ new Intl.NumberFormat().format(materials.get(ext[0]).price) }} ISK</span>
                <br/>= <span v-if="materials.has(ext[0])">{{ new Intl.NumberFormat().format(ext[1]*materials.get(ext[0]).price) }} ISK</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <EveCharacter deck v-for="character in characters"
                     :key="character.id"
                     :refresh="character.refresh"
                     :token="character.token"
                     :id="character.id"
                     :name="character.name"
                     :date="date"
                     @created="onPlanetCreated"
                     @removeChar="onRemoveChar"/>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import EveCharacter from './components/EveCharacter.vue'
import {createSSO} from "eve-sso-pkce";
import {getMaterials} from "@/services/Price.api";

let auth = createSSO({
  clientId: process.env.VUE_APP_CLIENT_ID,
  redirectUri: process.env.VUE_APP_REDIRECT_URI
})

export default {
  name: 'App',
  components: {
    EveCharacter,
    //Slider
  },
  data: () => {
    return {
      token: {},
      characters: [],
      dateManipulator: 0,
      currentIPH: 0,
      maxIPH: 0,
      materials: new Map(),
      extracted: new Map(),
    }
  },
  async created() {
    let storageCharacters = [];
    if (localStorage.characters) {
      storageCharacters = JSON.parse(localStorage.characters);
    }

    let urlParams = new URLSearchParams(location.search);
    if(
        urlParams.has('code') &&
        urlParams.has('state') &&
        urlParams.get('state') === localStorage.getItem('state')
    ) {
      auth.getAccessToken(urlParams.get('code'), localStorage.getItem('codeVerifier')).then((token)=>{
        storageCharacters.push(token.refresh_token);
        localStorage.characters = JSON.stringify(storageCharacters);
        this.refreshToken(token.refresh_token);
        window.history.replaceState({}, document.title, "/");
      })
    }

    storageCharacters.forEach((storageCharacter) => {
      this.refreshToken(storageCharacter);
    })

    let response = await getMaterials();
    this.materials = response[1];
  },
  computed: {
    date: function () {
      return new Date().setDate( new Date().getDate() + this.dateManipulator )
    },
  },
  methods: {
    sliderUpdate: function () {
      console.log("slider", this.date)
      this.maxIPH = 0;
      this.currentIPH = 0;
      this.extracted = new Map();
    },
    addCharacter: function () {
      auth.getUri(['esi-planets.manage_planets.v1']).then((result) => {
        localStorage.setItem('state', result.state);
        localStorage.setItem('codeVerifier', result.codeVerifier);
        window.location.href = result.uri;
      })
    },
    refreshToken: function (refresh_token) {
      if(refresh_token) {
        auth.refreshToken(refresh_token).then((token) => {
          this.characters.push({
            refresh: token.refresh_token,
            token: token.access_token,
            name: token.payload.name,
            id: parseInt(token.payload.sub.replace('CHARACTER:EVE:', ''))
          });
        }).catch((err) => {
          console.log('error with', refresh_token, err);
          this.onRemoveChar(refresh_token);
        })
      }
    },
    onPlanetCreated: function (currentIPH, maxIPH, extracted) {
      this.currentIPH += currentIPH;
      this.maxIPH += maxIPH;
      extracted.forEach((amount, type) => {
        if (this.extracted.has(type)) {
          this.extracted.set(type, this.extracted.get(type) + amount);
        } else {
          this.extracted.set(type, amount);
        }
      })
      this.extracted = new Map([...this.extracted.entries()].sort());
    },
    onRemoveChar: function (refresh) {
      let storageCharacters = JSON.parse(localStorage.characters);
      const index = storageCharacters.indexOf(refresh);
      if (index > -1) {
        storageCharacters.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.characters.forEach((char, index) => {
        if(char.refresh === refresh) {
          this.characters.splice(index, 1);
        }
      })
      localStorage.characters = JSON.stringify(storageCharacters);
    },
  }
}
</script>

