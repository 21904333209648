export let Schematic = new Map();

Schematic.set(65, {name: "Superconductors", cycleTime: 3600});
Schematic.set(66, {name: "Coolant", cycleTime: 3600});
Schematic.set(67, {name: "Rocket Fuel", cycleTime: 3600});
Schematic.set(68, {name: "Synthetic Oil", cycleTime: 3600});
Schematic.set(69, {name: "Oxides", cycleTime: 3600});
Schematic.set(70, {name: "Silicate Glass", cycleTime: 3600});
Schematic.set(71, {name: "Transmitter", cycleTime: 3600});
Schematic.set(72, {name: "Water-Cooled CPU", cycleTime: 3600});
Schematic.set(73, {name: "Mechanical Parts", cycleTime: 3600});
Schematic.set(74, {name: "Construction Blocks", cycleTime: 3600});
Schematic.set(75, {name: "Enriched Uranium", cycleTime: 3600});
Schematic.set(76, {name: "Consumer Electronics", cycleTime: 3600});
Schematic.set(77, {name: "Miniature Electronics", cycleTime: 3600});
Schematic.set(78, {name: "Nanites", cycleTime: 3600});
Schematic.set(79, {name: "Biocells", cycleTime: 3600});
Schematic.set(80, {name: "Microfiber Shielding", cycleTime: 3600});
Schematic.set(81, {name: "Viral Agent", cycleTime: 3600});
Schematic.set(82, {name: "Fertilizer", cycleTime: 3600});
Schematic.set(83, {name: "Genetically Enhanced Livestock", cycleTime: 3600});
Schematic.set(84, {name: "Livestock", cycleTime: 3600});
Schematic.set(85, {name: "Polytextiles", cycleTime: 3600});
Schematic.set(86, {name: "Test Cultures", cycleTime: 3600});
Schematic.set(87, {name: "Supertensile Plastics", cycleTime: 3600});
Schematic.set(88, {name: "Polyaramids", cycleTime: 3600});
Schematic.set(89, {name: "Ukomi Superconductor", cycleTime: 3600});
Schematic.set(90, {name: "Condensates", cycleTime: 3600});
Schematic.set(91, {name: "Camera Drones", cycleTime: 3600});
Schematic.set(92, {name: "Synthetic Synapses", cycleTime: 3600});
Schematic.set(94, {name: "High-Tech Transmitter", cycleTime: 3600});
Schematic.set(95, {name: "Gel-Matrix Biopaste", cycleTime: 3600});
Schematic.set(96, {name: "Supercomputers", cycleTime: 3600});
Schematic.set(97, {name: "Robotics", cycleTime: 3600});
Schematic.set(98, {name: "Smartfab Units", cycleTime: 3600});
Schematic.set(99, {name: "Nuclear Reactors", cycleTime: 3600});
Schematic.set(100, {name: "Guidance Systems", cycleTime: 3600});
Schematic.set(102, {name: "Neocoms", cycleTime: 3600});
Schematic.set(103, {name: "Planetary Vehicles", cycleTime: 3600});
Schematic.set(104, {name: "Biotech Research Reports", cycleTime: 3600});
Schematic.set(105, {name: "Vaccines", cycleTime: 3600});
Schematic.set(106, {name: "Industrial Explosives", cycleTime: 3600});
Schematic.set(107, {name: "Hermetic Membranes", cycleTime: 3600});
Schematic.set(108, {name: "Transcranial Microcontroller", cycleTime: 3600});
Schematic.set(109, {name: "Data Chips", cycleTime: 3600});
Schematic.set(110, {name: "Hazmat Detection Systems", cycleTime: 3600});
Schematic.set(111, {name: "Cryoprotectant Solution", cycleTime: 3600});
Schematic.set(112, {name: "Organic Mortar Applicators", cycleTime: 3600});
Schematic.set(113, {name: "Sterile Conduits", cycleTime: 3600});
Schematic.set(114, {name: "Nano-Factory", cycleTime: 3600});
Schematic.set(115, {name: "Self-Harmonizing Power Core", cycleTime: 3600});
Schematic.set(116, {name: "Recursive Computing Module", cycleTime: 3600});
Schematic.set(117, {name: "Broadcast Node", cycleTime: 3600});
Schematic.set(118, {name: "Integrity Response Drones", cycleTime: 3600});
Schematic.set(119, {name: "Wetware Mainframe", cycleTime: 3600});
Schematic.set(121, {name: "Water", cycleTime: 1800});
Schematic.set(122, {name: "Plasmoids", cycleTime: 1800});
Schematic.set(123, {name: "Electrolytes", cycleTime: 1800});
Schematic.set(124, {name: "Oxygen", cycleTime: 1800});
Schematic.set(125, {name: "Oxidizing Compound", cycleTime: 1800});
Schematic.set(126, {name: "Reactive Metals", cycleTime: 1800});
Schematic.set(127, {name: "Precious Metals", cycleTime: 1800});
Schematic.set(128, {name: "Toxic Metals", cycleTime: 1800});
Schematic.set(129, {name: "Chiral Structures", cycleTime: 1800});
Schematic.set(130, {name: "Silicon", cycleTime: 1800});
Schematic.set(131, {name: "Bacteria", cycleTime: 1800});
Schematic.set(132, {name: "Biomass", cycleTime: 1800});
Schematic.set(133, {name: "Proteins", cycleTime: 1800});
Schematic.set(134, {name: "Biofuels", cycleTime: 1800});
Schematic.set(135, {name: "Industrial Fibers", cycleTime: 1800});
