export let Materials = new Map();

Materials.set(2393, {name: "Bacteria", level: "P1"});
Materials.set(2396, {name: "Biofuels", level: "P1"});
Materials.set(3779, {name: "Biomass", level: "P1"});
Materials.set(2401, {name: "Chiral Structures", level: "P1"});
Materials.set(2390, {name: "Electrolytes", level: "P1"});
Materials.set(2397, {name: "Industrial Fibers", level: "P1"});
Materials.set(2392, {name: "Oxidizing Compound", level: "P1"});
Materials.set(3683, {name: "Oxygen", level: "P1"});
Materials.set(2389, {name: "Plasmoids", level: "P1"});
Materials.set(2399, {name: "Precious Metals", level: "P1"});
Materials.set(2395, {name: "Proteins", level: "P1"});
Materials.set(2398, {name: "Reactive Metals", level: "P1"});
Materials.set(9828, {name: "Silicon", level: "P1"});
Materials.set(2400, {name: "Toxic Metals", level: "P1"});
Materials.set(3645, {name: "Water", level: "P1"});
Materials.set(2329, {name: "Biocells", level: "P2"});
Materials.set(3828, {name: "Construction Blocks", level: "P2"});
Materials.set(9836, {name: "Consumer Electronics", level: "P2"});
Materials.set(9832, {name: "Coolant", level: "P2"});
Materials.set(44, {name: "Enriched Uranium", level: "P2"});
Materials.set(3693, {name: "Fertilizer", level: "P2"});
Materials.set(15317, {name: "Genetically Enhanced Livestock", level: "P2"});
Materials.set(3725, {name: "Livestock", level: "P2"});
Materials.set(3689, {name: "Mechanical Parts", level: "P2"});
Materials.set(2327, {name: "Microfiber Shielding", level: "P2"});
Materials.set(9842, {name: "Miniature Electronics", level: "P2"});
Materials.set(2463, {name: "Nanites", level: "P2"});
Materials.set(2317, {name: "Oxides", level: "P2"});
Materials.set(2321, {name: "Polyaramids", level: "P2"});
Materials.set(3695, {name: "Polytextiles", level: "P2"});
Materials.set(9830, {name: "Rocket Fuel", level: "P2"});
Materials.set(3697, {name: "Silicate Glass", level: "P2"});
Materials.set(9838, {name: "Superconductors", level: "P2"});
Materials.set(2312, {name: "Supertensile Plastics", level: "P2"});
Materials.set(3691, {name: "Synthetic Oil", level: "P2"});
Materials.set(2319, {name: "Test Cultures", level: "P2"});
Materials.set(9840, {name: "Transmitter", level: "P2"});
Materials.set(3775, {name: "Viral Agent", level: "P2"});
Materials.set(2328, {name: "Water-Cooled CPU", level: "P2"});
Materials.set(2358, {name: "Biotech Research Reports", level: "P3"});
Materials.set(2345, {name: "Camera Drones", level: "P3"});
Materials.set(2344, {name: "Condensates", level: "P3"});
Materials.set(2367, {name: "Cryoprotectant Solution", level: "P3"});
Materials.set(17392, {name: "Data Chips", level: "P3"});
Materials.set(2348, {name: "Gel-Matrix Biopaste", level: "P3"});
Materials.set(9834, {name: "Guidance Systems", level: "P3"});
Materials.set(2366, {name: "Hazmat Detection Systems", level: "P3"});
Materials.set(2361, {name: "Hermetic Membranes", level: "P3"});
Materials.set(17898, {name: "High-Tech Transmitters", level: "P3"});
Materials.set(2360, {name: "Industrial Explosives", level: "P3"});
Materials.set(2354, {name: "Neocoms", level: "P3"});
Materials.set(2352, {name: "Nuclear Reactors", level: "P3"});
Materials.set(9846, {name: "Planetary Vehicles", level: "P3"});
Materials.set(9848, {name: "Robotics", level: "P3"});
Materials.set(2351, {name: "Smartfab Units", level: "P3"});
Materials.set(2349, {name: "Supercomputers", level: "P3"});
Materials.set(2346, {name: "Synthetic Synapses", level: "P3"});
Materials.set(12836, {name: "Transcranial Microcontrollers", level: "P3"});
Materials.set(17136, {name: "Ukomi Super Conductors", level: "P3"});
Materials.set(28974, {name: "Vaccines", level: "P3"});
Materials.set(2867, {name: "Broadcast Node", level: "P4"});
Materials.set(2868, {name: "Integrity Response Drones", level: "P4"});
Materials.set(2869, {name: "Nano-Factory", level: "P4"});
Materials.set(2870, {name: "Organic Mortar Applicators", level: "P4"});
Materials.set(2871, {name: "Recursive Computing Module", level: "P4"});
Materials.set(2872, {name: "Self-Harmonizing Power Core", level: "P4"});
Materials.set(2875, {name: "Sterile Conduits", level: "P4"});
Materials.set(2876, {name: "Wetware Mainframe", level: "P4"});
Materials.set(2268, {name: "Aqueous Liquids", level: "P0"});
Materials.set(2305, {name: "Autotrophs", level: "P0"});
Materials.set(2267, {name: "Base Metals", level: "P0"});
Materials.set(2288, {name: "Carbon Compounds", level: "P0"});
Materials.set(2287, {name: "Complex Organisms", level: "P0"});
Materials.set(2307, {name: "Felsic Magma", level: "P0"});
Materials.set(2272, {name: "Heavy Metals", level: "P0"});
Materials.set(2309, {name: "Ionic Solutions", level: "P0"});
Materials.set(2073, {name: "Micro Organisms", level: "P0"});
Materials.set(2310, {name: "Noble Gas", level: "P0"});
Materials.set(2270, {name: "Noble Metals", level: "P0"});
Materials.set(2306, {name: "Non-CS Crystals", level: "P0"});
Materials.set(2286, {name: "Planktic Colonies", level: "P0"});
Materials.set(2311, {name: "Reactive Gas", level: "P0"});
Materials.set(2308, {name: "Suspended Plasma", level: "P0"});
