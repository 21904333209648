<template>
  <div>
    <b-card v-if="meta" no-body class="overflow-hidden bg-light mb-2" style="max-width: 540px;">
      <b-row no-gutters>
        <b-col md="3">
          <b-card-img v-if="meta.type_id" :src="'https://images.evetech.net/types/' + meta.type_id + '/icon?size=128'" alt="Image" class="rounded-0"></b-card-img>
          <p>⚙{{ factories.size }} <!--img v-if="type" :src="'https://images.evetech.net/types/' + type + '/icon?size=32'"/--></p>
        </b-col>
        <b-col md="9">
          <b-card-body :title="meta.name">
            <b-card-text v-if="extractors.length === 0 && factories.size > 0">
              Factory Planet
            </b-card-text>
            <b-card-text v-for="extractor in extractors" :key="extractor.pin_id">
              Remaining extraction time:
              <b-progress :max="100" animated>
                <b-progress-bar :value="extractor.percentage" :label="elapsedTime(extractor.remaining)"></b-progress-bar>
              </b-progress>
              <!-- Cycles done: {{ cyclesDone(extractor.install_time, extractor.extractor_details.cycle_time) }}<br/> -->
            </b-card-text>

            <b-card-text>
              Current earnings:
              <b-progress :max="100">
                <b-progress-bar
                    :variant="barColorIPH()"
                    :value="IPH / maxIPH * 100"
                    :label="new Intl.NumberFormat().format(Math.round(IPH))+ ' / '+ new Intl.NumberFormat().format(Math.round(maxIPH))+ ' ISK/h'"
                ></b-progress-bar>
              </b-progress>
            </b-card-text>
            <b-card-text>
              <b-list-group horizontal>
                <b-list-group-item  v-for="ext in extracted" :key="ext[0]">
                  <img v-if="ext[0]" :src="'https://images.evetech.net/types/' + ext[0] + '/icon?size=32'"/> {{ new Intl.NumberFormat().format(ext[1]) }}
                </b-list-group-item>
              </b-list-group>
            </b-card-text>
            <!--b-card-text>
              The data is here, but is only updated on planet visit
              <b-list-group horizontal>
                <b-list-group-item v-for="storage in storages" :key="storage[0]">
                  <img :src="'https://images.evetech.net/types/' + storage[1].contents[0].type_id + '/icon?size=32'"/> {{ new Intl.NumberFormat().format(storage[1].contents[0].amount) }}
                </b-list-group-item>
              </b-list-group>
            </b-card-text-->
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { Api } from "eve-esi-swaggerts";
import {getMaterials} from '@/services/Price.api.js';
import { Schematic } from "@/models/Schematic";

export default {
  name: "EvePlanet",
  props: {
    id: Number,
    token: String,
    date: Number,
    planet: {
      last_update: String,
      num_pins: Number,
      owner_id: Number,
      planet_id: Number,
      planet_type: "temperate" | "barren" | "oceanic" | "ice" | "gas" | "lava" | "storm" | "plasma",
      solar_system_id: Number,
      upgrade_level: Number
    },
  },
  data: () => {
    return {
      meta: {},
      data: {},
      percentage: 50,
      IPH: 0,
      maxIPH: 0,
      materials: new Map(),
      extracted: new Map(),
      extractedFirstH: new Map(),
      extractedLastH: new Map(),
    }
  },
  async created() {
    let eveAPI = new Api();
    const data = await eveAPI.characters.getCharactersCharacterIdPlanetsPlanetId(this.id, this.planet.planet_id, {token: this.token});
    this.data = data.data;

    const meta = await eveAPI.universe.getUniversePlanetsPlanetId(this.planet.planet_id);
    this.meta = meta.data;

    let response = await getMaterials();
    this.materials = response[1];

    this.extracted = new Map();
    this.extractors.forEach((extractor) => {
      this.simulateExtractor(extractor);
    })
    if(this.extractors.length === 0 && this.factories.size > 0) {
      this.storages.forEach((storage) => {
        this.simulateStorage(storage);
      })
    }

    this.extractedFirstH.forEach((amount, type) => {
      this.maxIPH += Math.round(this.materials.get(type).price * amount);
    })
    this.extractedLastH.forEach((amount, type) => {
      this.IPH += (this.materials.get(type).price * amount)/2;
    })
    this.$emit('created', this.IPH, this.maxIPH, this.extracted);
  },
  unmounted() {
    this.extracted.forEach((amount, type) => {
      this.extracted.set(type, amount * -1)
    })
    this.$emit('created', -this.IPH, -this.maxIPH, this.extracted);
  },
  computed: {
    now: function () {
      return new Date(this.date);
    },
    routes: function () {
      let routes = new Map();
      if (this.data["routes"]) {
        this.data["routes"].forEach((route) => {
          if(routes.has(route.source_pin_id)) {
            let current_route = routes.get(route.source_pin_id);
            current_route.push(route);
            routes.set(route.source_pin_id, current_route);
          } else {
            routes.set(route.source_pin_id, [route]);
          }
        })
      }
      return routes;
    },
    storages: function () {
      let storages = new Map();
      if (this.data["pins"]) {
        this.data["pins"].forEach((pin) => {
          if (!pin.schematic_id && !pin.extractor_details && pin.contents && pin.contents[0]) {
            storages.set(pin.pin_id, pin);
          }
        })
      }
      return storages;
    },
    factories: function () {
      // let basicFactoryIDs = [2469,2471,2473,2481,2483,2490,2492,2493];
      // let advancedFactoryIDs = [2470,2472,2474,2480,2484,2485,2491,2494];
      // let hightechFactoryIDs = [2475,2482];
      let factories = new Map();
      if (this.data["pins"]) {
        this.data["pins"].forEach((pin) => {
          if (pin.schematic_id) {
            factories.set(pin.pin_id, pin);
          }
        })
      }
      return factories;
    },
    extractors: function () {
      let extractors = [];
      if (this.data["pins"]) {
        this.data["pins"].forEach((pin) => {
          if (pin.extractor_details) {
            pin.diff = (new Date(pin.expiry_time).getTime() - new Date(pin.install_time).getTime()) / 1000;
            pin.remaining = (new Date(pin.expiry_time).getTime() - this.now.getTime()) / 1000;
            if(pin.remaining<0) { pin.remaining = 0; }
            pin.percentage = pin.diff > 0 ? (pin.remaining / pin.diff) * 100 : 0;
            extractors.push(pin);
          }
        })
      }
      return extractors;
    }
  },

  methods: {
    barColorIPH: function () {
      let percentageIPH = this.IPH / this.maxIPH * 100;
      return percentageIPH >= 75 ? 'success' : (percentageIPH >= 50 ? 'warning' : 'danger');
    },
    cyclesDone: function (install_time, cycle_time) {
      let elapsed = (this.now.getTime() - new Date(install_time).getTime()) / 1000 / 60;
      if(elapsed<0) { elapsed = 0; }
      return Math.floor(elapsed / cycle_time);
    },
    elapsedTime: function(epoch) {
      //We are assuming that the epoch is in seconds
      let days = epoch / 3600 / 24,
          hours = (epoch % (3600 * 24)) / 3600,
          minutes = (hours % 1) * 60;
      return Math.floor(days) + "d, " + Math.floor(hours) + "h, " + Math.round(minutes) + "m";
    },
    addExtract: function (type, amount, time, end_time) {
      if (this.extracted.has(type)) {
        this.extracted.set(type, this.extracted.get(type) + amount);
      } else {
        this.extracted.set(type, amount);
      }
      if(time < 60/* && type === 3683*/) {
        if (this.extractedFirstH.has(type)) {
          this.extractedFirstH.set(type, this.extractedFirstH.get(type) + amount);
        } else {
          this.extractedFirstH.set(type, amount);
        }
        // console.log(time, "first", type, amount, this.extractedFirstH.get(type));
      }
      if(time >= end_time-120/* && type === 3683*/) {
        if (this.extractedLastH.has(type)) {
          this.extractedLastH.set(type, this.extractedLastH.get(type) + amount);
        } else {
          this.extractedLastH.set(type, amount);
        }
        // console.log(time, "last", type, amount, this.extractedLastH.get(type));
      }
    },
    simulateStorage: function (storage) {
      let end_time = 60 * 18; // TODO Ende finden
      let storage_routes = this.routes.get(storage.pin_id);
      if(!storage_routes) return 'empty ' + storage.pin_id;
      storage_routes.forEach((storage_route) => {
        let factory = this.factories.get(storage_route.destination_pin_id);
        let raw_type = storage_route.content_type_id;
        let quantity = storage_route.quantity;
        let schematic = Schematic.get(factory.schematic_id);
        // let name = schematic.name;
        let factory_cycle_time = schematic.cycleTime / 60;
        for(let time = 0; time < end_time; time+=15) {
          //TODO Extraced von der App ziehen
          if ((this.extracted.has(raw_type) && this.extracted.get(raw_type)>=quantity) && time % factory_cycle_time === 0) {
            let factory_routes = this.routes.get(storage_route.destination_pin_id);
            factory_routes.forEach((factory_route) => {
              let produced_quantity = factory_route.quantity;
              let produced_type = factory_route.content_type_id;
              this.addExtract(raw_type, -quantity, time, end_time);
              this.addExtract(produced_type, produced_quantity, time, end_time);
              // console.log(factory_route);

              // TODO: Add P2 on single Planet...
            });
          }
        }
      })
    },
    simulateExtractor: function (extractor) {
      let cycle_time = extractor.extractor_details.cycle_time / 60;
      let cycles_done = this.cyclesDone(extractor.install_time, cycle_time);
      let extractorValues = this.calculateExtractorValues(extractor.diff, extractor.extractor_details.cycle_time, extractor.extractor_details.qty_per_cycle);
      let end_time = (cycles_done + 1) * cycle_time;
      // console.log(end_time/60);
      for(let time = 0; time < end_time; time+=15) {
        if(time % cycle_time === 0) { //  && time / cycle_time >= 1
          let extracted_quantity = extractorValues[time / cycle_time];
          let extracted_type = extractor.extractor_details.product_type_id;
          this.addExtract(extracted_type, extracted_quantity, time, end_time);
        }
        let extractor_routes = this.routes.get(extractor.pin_id);
        extractor_routes.forEach((extractor_route) => {
          let storage_routes = this.routes.get(extractor_route.destination_pin_id);
          this.simulateFactoriesFromStorage(storage_routes, time, end_time);
        })
      }
    },
    simulateFactoriesFromStorage: function (storage_routes, time, end_time) {
      storage_routes.forEach((storage_route) => {
        let factory = this.factories.get(storage_route.destination_pin_id);
        let raw_type = storage_route.content_type_id;
        let quantity = storage_route.quantity;
        let schematic = Schematic.get(factory.schematic_id);
        // let name = schematic.name;
        let factory_cycle_time = schematic.cycleTime / 60;
        if (this.extracted.has(raw_type) && this.extracted.get(raw_type)>=quantity && time % factory_cycle_time === 0) {
          let factory_routes = this.routes.get(storage_route.destination_pin_id);
          factory_routes.forEach((factory_route) => {
            let produced_quantity = factory_route.quantity;
            let produced_type = factory_route.content_type_id;
            this.addExtract(raw_type, -quantity, time, end_time);
            this.addExtract(produced_type, produced_quantity, time, end_time);

            /*this.storages.forEach((storage) => {
              if (storage.pin_id === factory_route.destination_pin_id && storage.contents[0] && storage.contents[0].amount) {
                console.log(storage.contents[0].amount);
              }
            })*/
            // Recursive extra factories
            let storage_routes_2 = this.routes.get(factory_route.destination_pin_id);
            if (storage_routes_2) {
              this.simulateFactoriesFromStorage(storage_routes_2, time, end_time);
            }
          });
        }
      })
    },
    calculateExtractorValues: function(duration = 171000, cycleTime = 30 * 60, quantityPerCycle = 6965) {
      //These constants are the defaults in dgmAttributeTypes. They may change.
      let decayFactor = 0.012; //Dogma attribute 1683 for this pin typeID
      let noiseFactor = 0.8;   //Dogma attribute 1687 for this pin typeID

      let numIterations = duration / cycleTime;
      let barWidth = cycleTime / 900;
      let values = [];
      for (let i = 0; i < numIterations; i++) {
        let t = (i + 0.5) * barWidth;
        let decayValue = quantityPerCycle / (1 + t * decayFactor);
        let phaseShift = Math.pow(quantityPerCycle, 0.7);

        let sinA = Math.cos(phaseShift + t * (1 / 12));
        let sinB = Math.cos(phaseShift / 2 + t * 0.2);
        let sinC = Math.cos(t * 0.5);

        let sinStuff = (sinA + sinB + sinC) / 3;
        sinStuff = Math.max(sinStuff, 0);

        let barHeight = decayValue * (1 + noiseFactor * sinStuff);

        values[i] = Math.round(barWidth * barHeight);
      }
      return values;
    }
  }
}
</script>

<style scoped>
  .progress-bar {
    text-shadow: 0px 0px 2px black;
    overflow: visible !important;
    padding-left: 5px;
  }
</style>
